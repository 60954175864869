<template lang="pug">
  div
    .container
      h1 {{title}}
      router-link(to="/") Top
</template>

<script>
export default {
  // データオブジェクト
  data() {
    return {
      title: this.$route.params.id || ''
    }
  },
  // 算出プロパティ
  computed: {
  },
  // メソッド
  methods: {
  },
  // コンポーネント
  components: {
  },
  // インスタンス作成後
  created() {
    console.log('created')
  },
  // マウント後
  mounted() {
    console.log('mounted')
    this.$nextTick(() => {
      // ビュー全体がレンダリングされた後にのみ実行されるコード
      console.log('nextTick')
    })
  },
  // データ変更後
  updated() {
    console.log('updated')
  },
  // インスタンス破棄前
  beforeDestroy() {
    console.log('beforeDestroy')
  }
}
</script>